import './App.css';
import {QueryClient, QueryClientProvider} from "react-query";
import Main from "./Main";
import axios from "axios";
import * as LuigiClient from "@luigi-project/client";
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    axios.defaults.headers.common['x_mail'] = localStorage.getItem("username");
    axios.defaults.headers.common['x_code'] = localStorage.getItem("password");

    LuigiClient.addInitListener(initialContext => {
        axios.defaults.headers.common['x_token'] = LuigiClient.getToken();
    });

      const queryClient = new QueryClient();

  return (
      <QueryClientProvider client={queryClient}>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
          />
          <Main/>
          <a href={"https://lpa-ug.de/impressum"}>Impressum</a><br />
          <a href={"https://lpagents.de/datenschutz.php"}>Datenschutz</a><br /><br />
          <a href={"https://go.lpagents.de/discord"}>Discord</a><br />
          <a href={"https://www.hltv.org/results?event=6140"}>Ergebnisse</a>
      </QueryClientProvider>
  );
}

export default App;
