import axios from "axios";
import {useQuery} from "react-query";
import MatchCard from "./components/matchCard";
import IconButton from "@mui/material/IconButton";
import {SaveSharp} from "@mui/icons-material";
import {Tab, Tabs} from "@mui/material";
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

function Main() {
    const [value, setValue] = React.useState(0);

    const {isLoading: matchLoading, error: matchError, data: matchData} = useQuery("matchData", () =>
            axios.get(
                "https://api.lpa-ug.de/csBet/match/",
            ).then((res) => res.data)
        ,
        {
            // dont Refetch the data every second
            // refetchInterval: 1000,
        }
    );

    const {isLoading: resultsLoading, error: resultsError, data: resultsData} = useQuery("resultsData", () =>
            axios.get(
                "https://api.lpa-ug.de/csBet/result/",
            ).then((res) => res.data)
        ,
        {
            // dont Refetch the data every second
            // refetchInterval: 1000,
        }
    );

    const handleSubmission = e => {
        e.preventDefault();

        const username = e.target[0].value;
        const password = e.target[1].value;

        localStorage.setItem("username", username);
        localStorage.setItem("password", password);

        window.location.reload();
    }

    const {isLoading: betLoading, error: betError, data: betData} = useQuery("betData", () =>
            axios.get(
                "https://api.lpa-ug.de/csBet/bet/",
            ).then((res) => res.data)
        ,
        {
            // dont Refetch the data every second
            // refetchInterval: 1000,
        }
    );

    const usernameSet = localStorage.getItem("username") !== undefined && localStorage.getItem("username") !== null && localStorage.getItem("username") !== "";

    if (usernameSet && (matchLoading || betLoading || resultsLoading)) return "Loading...";

    if (matchError || betError || resultsError || !usernameSet) {
        return (
            <div>
                <form onSubmit={handleSubmission}>

                    <input className={"textfield"} placeholder={"Email"}/>
                    <input className={"textfield"} placeholder={"Passwort"}/>

                    <IconButton type="submit" aria-label="save">
                        <SaveSharp sx={{height: 38, width: 38}}/>
                    </IconButton>
                </form>
            </div>
        )
    }

    const resultItems = resultsData.map((result) => {

            return <div>
                {result.username}: {result.points} Punkte
            </div>
        }
    );

    if (matchData.length === 0) {
        return (
            <div>
                <l>Diese Seite ist noch in der Beta und daher leider nicht perfekt. Denke dran, neben jeder Vorhersage auf
                    das "Speichern"-Symbol zu drücken, um deine Vorhersage zu speichern. Wenn du deine Vorhersage nach dem
                    geplanten Start des Games abgibst oder änderst, wird sie ungültig. Profitipp: Nach dem Wetten einmal die Seite neuladen. Wenn eure Bets dann noch drin stehen, war das Speichern erfolgreich :D
                </l>

                <div className="content-container">
                    <div className="row">
                        <div className="left-panel box">
                            Lehn' dich zurück, hier gibt's gerade nichts zu tun. 🏝
                        </div>
                        <div className="right-panel box">
                            <ul>{resultItems}</ul>
                            <l>Ergebnis genau richtig getippt: 3 Punkte. Sieger korrekt gewählt: 2 Punkte. Beispiel-Tipp: 2-1, NICHT 16-5.</l>
                        </div>
                    </div>
                </div>


            </div>)
            ;
    }

    const listItems = matchData.map((match) => {
            let bet = betData.filter(f => f.match === match.id)[0];

            return <div>
                <MatchCard match={match} bet={bet}>

                </MatchCard>
            </div>
        }
    );


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <l>Diese Seite ist noch in der Beta und daher leider nicht perfekt. Denke dran, neben jeder Vorhersage auf
                das "Speichern"-Symbol zu drücken, um deine Vorhersage zu speichern. Wenn du deine Vorhersage nach dem
                geplanten Start des Games abgibst oder änderst, wird sie ungültig. Profitipp: Nach dem Wetten einmal die Seite neuladen. Wenn eure Bets dann noch drin stehen, war das Speichern erfolgreich :D
            </l>

            <div className="content-container">
                <div className="row">
                    <div className="left-panel box">
                        <ul>{listItems}</ul>
                    </div>
                    <div className="right-panel box">
                        <Tabs
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            <Tab label="Playoffs" {...a11yProps(0)} />
                            <Tab label="Beta" {...a11yProps(1)} />
                        </Tabs>
                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} >
                                <ul>{resultItems}</ul>
                            </TabPanel>
                            <TabPanel value={value} index={1} >

                                <ul>derMiniwini: 25 Punkte</ul>
                                    <ul>tobi: 20 Punkte</ul>
                                        <ul>Moritz: 20 Punkte</ul>
                                            <ul>Goratos: 19 Punkte</ul>
                                                <ul>Leon: 17 Punkte</ul>
                                                    <ul>Thies: 13 Punkte</ul>
                                                        <ul>JHeddendorp: 10 Punkte</ul>
                                                            <ul>Applebaum123: 10 Punkte</ul>
                                                                <ul>LordChew: 9 Punkte</ul>
                                                                    <ul>Franz: 6 Punkte</ul>
                            </TabPanel>
                        </SwipeableViews>

                        <l>Ergebnis genau richtig getippt: 3 Punkte. Sieger korrekt gewählt: 2 Punkte. Beispiel-Tipp: 2-1, NICHT 16-5.</l>
                    </div>
                </div>
            </div>


        </div>)
        ;

}

export default Main;