import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {SaveSharp} from "@mui/icons-material";
import axios from "axios";
import {toast} from "react-toastify";

export default function MatchCard({match, bet}) {
    const subtitle = match.team1_name + " vs. " + match.team2_name;
    const title = match.name;
    const leftPoints = bet?.team1_points;
    const rightPoints = bet?.team2_points;
    const matchId = match.id;
    const betId = bet?.id;

    useTheme();

    const handleSubmission = e => {
        e.preventDefault();

        const team1_points = e.target[0].value;
        const team2_points = e.target[1].value;
        const id = e.target.id;

        if (id[0] === "m") {
            const toast_id = toast.loading("Einen Moment bitte...");

            const matchId = id.substring(1);

            axios.post(
                "https://api.lpa-ug.de/csBet/bet/",
                {match: matchId, team1_points: team1_points, team2_points: team2_points}
            )
                .then(data => {
                    toast.update(toast_id, {
                        render: '🦄 Bet gespeichert!',
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        isLoading: false,
                        type: "success"
                    });
                })
                .catch(error => {
                    toast.update(toast_id, {
                        render: '🦄 Bet nicht gespeichert!',
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        isLoading: false,
                        type: "error"
                    });
                    console.log(error);
                })


        } else {
            const toast_id = toast.loading("Please wait...");

            axios.patch(
                "https://api.lpa-ug.de/csBet/bet/" + id + "/",
                {team1_points: team1_points, team2_points: team2_points}
            )
                .then(data => {
                    toast.update(toast_id, {
                        render: '🦄 Bet gespeichert!',
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        isLoading: false,
                        type: "success"
                    });
                })
                .catch(error => {
                    toast.update(toast_id, {
                        render: '🦄 Bet nicht gespeichert!',
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        isLoading: false,
                        type: "error"
                    });
                    console.log(error);
                })


        }
    };

    return (
        <Card sx={{display: 'flex'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CardContent sx={{flex: '1 0 auto'}}>
                    <Typography component="div" variant="h6">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {subtitle}
                    </Typography>
                </CardContent>
                <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                    <form onSubmit={handleSubmission} id={betId ?? "m" + matchId}>
                        <input
                            defaultValue={leftPoints}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                        <l> - </l>
                        <input defaultValue={rightPoints}
                               onKeyPress={(event) => {
                                   if (!/[0-9]/.test(event.key)) {
                                       event.preventDefault();
                                   }
                               }}
                        />

                        <IconButton type="submit" aria-label="save">
                            <SaveSharp sx={{height: 38, width: 38}}/>
                        </IconButton>
                    </form>
                </Box>
            </Box>
        </Card>
    );
}